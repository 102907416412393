<template>
    <div class="cover row-center">
        <div class="popup">
            <h2>¡Hola!</h2>
            <p>Necesitamos que completes tu perfil</p>
            <router-link class="boton-azul" to="/es/mi-perfil/completa-tu-perfil">Continuar</router-link>
            <a @click="set_hide_in_session_completeprofile" class="more-later">Más tarde</a>
        </div>
    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'popup-perfil',
        mounted(){
            this.setData();
        },
        data:() => ({   
            data : ''
        }),
        methods: {
            ...mapActions(['getAlumni', 'saveAlumni','set_hide_in_session_completeprofile']),
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                });
            },

        }
    }
</script>


<style lang="scss" scoped>
    .cover {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }

    .popup {
        background: white;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        min-height: 200px;
    }

    .popup h2 {
        margin-bottom: 20px;
        font-size: 26px;
        text-align: center;
    }

    .popup p {
        font-size: 16px;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        display: inline-block;
    }

    .popup .more-later {
        margin-top: 10px;
        font-size: 14px;
        display: inline-block;
        text-decoration: underline;
    }
</style>