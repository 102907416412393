import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';

// import moment from 'moment';

const auth = {
    state: {
        logged: !!window.localStorage.getItem('_token'),
    },
    getters: {
        getLogged: state => state.logged,
    },
    actions: {
        createAlumni({ commit }, data) {

            return new Promise((resolve) => {
                axios.post('/api/auth/register', data).then(response => {
                    if (response.data.status) {
                        commit('setAlert', 1001);
                        resolve(true);
                    }
                    else {
                        resolve(false)
                    }
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        login: ({ commit, dispatch }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`api/auth/login`, data).then(result => {

					if (result.data.status) {
						dispatch('setLogged',result.data.token);
						resolve(true);
					} else {
						commit('setAlert', 1004)
						resolve(false);
					}

				}).catch(error => {
					reject(error);
				});
			});
		},

        setLogged({ commit,dispatch },token){
			window.localStorage.setItem('_token', token);
			
			commit('setLogged');

			dispatch('initapp');
		},

        requestRecoveryPass: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/auth/send_password`, data).then(result => {
					if (result.data.status) {
						resolve(true);
						commit('setAlert', 1002)
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					reject(error);
				});

			});
		},

		logout: ({ commit }) => {

			return new Promise((resolve) => {
				axios.post(`api/auth/logout`).then(() => {
					commit('setLogout');
					resolve(true);
				}).catch(() => {
					commit('setLogout');
					resolve(true);
				});

			});
		},

        sendPass: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/auth/reset_password`, data).then(result => {
					if (result.data.status) {
						resolve(true);
						commit('setAlert', 1002)
					} else {
						resolve(false);
                        commit('setAlert', 1000)
					}
				}).catch(error => {
					reject(error);
				});

			});
		},
        activarcuenta: ({ commit,dispatch }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/auth/verificate`, data).then(result => {
					if (result.data.status) {
						resolve(true);
						dispatch('setLogged',result.data.token);
						commit('setAlert', 1008)
					} else {
						resolve(false);
                        commit('setAlert', 1000)
					}
				}).catch(error => {
					reject(error);
				});

			});
		},

    },
    mutations: {

        setLogged(state) {

			if (window.localStorage.getItem('_token')) {

				const token = window.localStorage.getItem("_token");


				axios.defaults.headers.common['authorization'] = `Bearer ${token}`;

				state.logged = true;
				state.token = token;

			}

		},

		setLogout(state) {
			state.logged = false;
			state.set_hide_in_session_completeprofile = false;
			window.localStorage.clear();
			window.sessionStorage.clear();
		}


    }
}

export default auth;
