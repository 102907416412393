import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const formularios = {
	state: {
		alertcode : null,
        messages: null
	},
	getters: {
        getAlertCode: state => state.alertcode,
        getAlertMessage: state => state.messages

	},
	actions: {
        eraseAlertCode:({commit}) => {
            commit('eraseAlert');
        },
        setAlert({commit}, code){
            commit('setAlert',code)
        },
        setCustomAlert({commit},messages){
            commit('setCustomAlert',messages);
        }
	},
	mutations: {
        eraseAlert(state){
            state.alertcode = null;
            state.messages = null;
        },
        setAlert(state, code){
            state.alertcode = code;
        },
        setCustomAlert(state,messages){
            state.messages = messages;
        }
	}
}

export default formularios;
