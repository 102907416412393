import Vue from 'vue'
import VueRouter from 'vue-router'


import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/es/general/colabora',
    name: 'colabora',
    component: () => import(/* webpackChunkName: "colabora" */ '../views/colabora.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/contacto.vue')
  },
  {
    path: '/es/general/registro',
    name: 'registro',
    component: () => import(/* webpackChunkName: "registro" */ '../views/registro.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/solicitar-contrasena',
    name: 'solicitar-contrasena',
    component: () => import(/* webpackChunkName: "login" */ '../views/request-password.vue')
  },
  {
    path: '/auth/reset-password',
    name: 'establecer-contrasena',
    component: () => import(/* webpackChunkName: "login" */ '../views/set-password.vue')
  },
  {
    path: '/activar-cuenta',
    name: 'activar-cuenta',
    component: () => import(/* webpackChunkName: "activar-cuenta" */ '../views/activar-cuenta.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-personales',
    name: 'datos-personales',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-personales.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-academicos',
    name: 'datos-academicos',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-academicos.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-profesionales',
    name: 'datos-profesionales',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-profesionales.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-elumni',
    name: 'datos-elumni',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-elumni.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-internacional',
    name: 'datos-internacional',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-internacional.vue')
  },
  {
    path: '/es/mi-perfil/mis-datos-familiares',
    name: 'datos-familiares',
    component: () => import(/* webpackChunkName: "login" */ '../views/mis-datos-familiares.vue')
  },
  {
    path: '/es/mi-perfil/completa-tu-perfil',
    name: 'completa-tu-perfil',
    component: () => import(/* webpackChunkName: "login" */ '../views/completa-tu-perfil.vue')
  },
  {
    path: '/es/comunicacion/noticias',
    name: 'noticias',
    component: () => import(/* webpackChunkName: "noticias" */ '../views/noticias.vue')
  },
  {
    path: '/es/comunicacion/noticias/:id?',
    name: 'noticia',
    component: () => import(/* webpackChunkName: "noticia" */ '../views/noticia.vue')
  },
  {
    path: '/es/comunicacion/entrevistas',
    name: 'entrevistas',
    component: () => import(/* webpackChunkName: "entrevistas" */ '../views/entrevistas.vue')
  },
  {
    path: '/es/comunicacion/entrevistas/:id?',
    name: 'entrevista',
    component: () => import(/* webpackChunkName: "entrevista" */ '../views/entrevista.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import(/* webpackChunkName: "eventos" */ '../views/eventos.vue')
  },
  {
    path: '/eventos/:id',
    name: 'evento-ficha',
    component: () => import(/* webpackChunkName: "evento-ficha" */ '../views/evento-ficha.vue')
  },
  {
    path: '/es/general/politica-privacidad',
    name: 'politica-privacidad',
    component: () => import(/* webpackChunkName: "politica-privacidad" */ '../views/politica-privacidad.vue')
  },
  {
    path: '/es/general/aviso-legal',
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "aviso-legal" */ '../views/aviso-legal.vue')
  },
  {
    path: '/es/general/politica-cookies',
    name: 'politica-cookies',
    component: () => import(/* webpackChunkName: "politica-cookies" */ '../views/politica-cookies.vue')
  },
  {
    path : '/auth/login',
    redirect : '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return (savedPosition) ? savedPosition : {x: 0, y: 0}
  }
});

router.beforeEach((to, from, next) => {
  //COMPRUEBO INICIO DE SESIÓN Y SI ESTÁ LOGADO

  if (to.matched.some(route => route.meta.requireAuth) && !store.getters.getLogged) {
    next({ name: 'login' })
    return
  }

  if(to.name == 'login' && store.getters.getLogged){
    next({ name: 'home' });
    return;
  }

  next()
});

export default router
