<template>
    <div class="cover-perfil cover-cerrar js-perfil" :active="showmenu" v-show="showmenu">
        <div class="contenedor-popup row-center">
            <div class="popup">

                <div class="contenedor-datos-perfil">
                    <a class="cerrar-popup material-icons" @click="toggleMenuPerfil">✕</a>

                    <div class="contenedor-imagen-perfil">
                        <span class="imagen" v-if="alumnidata.image" :style="`background:url(${alumnidata.image.url}) center/cover no-repeat`"></span>
                        <span class="imagen" v-else></span>
                    </div>

                    <h2>{{alumnidata.full_name}}</h2>
                    <h3 v-if="alumnidata && alumnidata.study_university.length != 0">
                        {{alumnidata.study_university[alumnidata.study_university.length-1].study.name}}
                        {{alumnidata.study_university[alumnidata.study_university.length-1].year}}
                    </h3>

                </div>


                <div class="contenedor-opciones-editar">

                    <router-link  to="/es/mi-perfil/mis-datos-personales" >Datos personales </router-link>
                    <router-link  to="/es/mi-perfil/mis-datos-academicos" >Datos académicos UFV</router-link>
                    <router-link  to="/es/mi-perfil/mis-datos-profesionales" >Datos profesionales </router-link>
                    <router-link  to="/es/mi-perfil/mis-datos-elumni" v-if="alumnidata && alumnidata.bowls.includes(2)">Datos Elumni </router-link>
                    <router-link  to="/es/mi-perfil/mis-datos-internacional">Datos Internacional </router-link>
                    <router-link  to="/es/mi-perfil/mis-datos-familiares">Datos familiares </router-link>
                    


                    <a @click="requestLogout" class="salir" :loading="loadinglogout">Desconectar</a>

                </div>
            </div>
            <span @click="showmenu = !showmenu" v-if="showmenu" class="capa-cerrar-popup"></span>
        </div>
    </div>
</template>

<script>

    import {mapActions,mapGetters} from 'vuex';

    import router from '@/router'

    export default{
        name : 'perfil-menu',
        data : () => ({
            loadinglogout : false
        }),
        methods : {
            ...mapActions(['logout','toggleMenuPerfil']),
            requestLogout(){
                this.loadinglogout = true;
                this.logout().then(r =>  {
                    if(r){
                        router.push({
                            name : 'home'
                        })
                    }                     
                })
            }
        },
        computed : {
            ...mapGetters({
                showmenu : 'getShowmenuperfil',
                alumnidata : 'getAlumnidata'
            })
        },
        watch : {
            '$route'(){
                if(this.showmenu){
                    this.toggleMenuPerfil();
                }
            }
        }
    }
</script>