import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';

// import moment from 'moment';

const alumni = {
    state: {
        alumnidata : '',
        show_completeprofile : false,
        hide_in_session_completeprofile : window.sessionStorage.getItem('set_hide_in_session_completeprofile') || false
    },
    getters: {
        getAlumnidata: state => state.alumnidata,
        getCompleteprofile : state => (state.show_completeprofile && !state.hide_in_session_completeprofile) 
    },
    actions: {
        getAlumni({ commit,dispatch }) {

            return new Promise((resolve) => {
                axios.get('/api/user').then(response => {
                    resolve(response.data)
                    commit('setalumnidata', response.data)
                }).catch(() => {
                    dispatch('logout');
                    resolve(false);
                });
            });
        },

        saveAlumni({ commit,dispatch }, data) {


            if(typeof data.image == "object"){
                delete data.image;
            }

            return new Promise((resolve) => {
                axios.post('/api/user/save', data).then(response => {
                    resolve(response.data)
                    commit('setAlert', 1003);
                    dispatch('getAlumni')
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        getAcademicos({ commit }) {

            return new Promise((resolve) => {
                axios.post('/api/user/studies').then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        saveAcademico({ commit,dispatch },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/studies/save',data).then(response => {
                    resolve(response.data.status);
                    dispatch('getAlumni');
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },
        deleteAcademico({ commit,dispatch },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/studies/delete',data).then(response => {
                    resolve(response.data.status);
                    dispatch('getAlumni');
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        getProfesionales({ commit }) {

            return new Promise((resolve) => {
                axios.post('/api/user/profesional').then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        saveProfesional({ commit },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/profesional/save',data).then(response => {
                    resolve(response.data.status)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },
        deleteProfesional({ commit },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/profesional/delete',data).then(response => {
                    resolve(response.data.status)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        saveFamiliar({ commit }, data) {

			

			return new Promise((resolve) => {
				axios.post('/api/user/family_comments/save/', data).then(response => {
					if (response.data.status) {
						commit('setAlert', 1005);
						resolve(true);
					}
					else {
						resolve(false);
						commit('setCustomAlert', response.data.messages);
					}
				})
					.catch(error => {

						commit('setCustomAlert', error.response.data.messages);
						resolve(false);
					});
			});
		},
        
        getFamiliar({ commit }) {

            return new Promise((resolve) => {
                axios.post('/api/user/family_comments').then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        deleteFamiliar({ commit },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/family_comments/delete',data).then(response => {
                    resolve(response.data.status)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        getIdioma({ commit }) {

            return new Promise((resolve) => {
                axios.post('/api/user/langs').then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        saveIdioma({ commit },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/langs/save',data).then(response => {
                    resolve(response.data.status)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },
        deleteIdioma({ commit },data) {

            return new Promise((resolve) => {
                axios.post('/api/user/langs/delete',data).then(response => {
                    resolve(response.data.status)
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },
        set_hide_in_session_completeprofile({commit}){
            commit('set_hide_in_session_completeprofile', true);
        }


    },
    mutations: {
        setalumnidata(state,data){
            state.alumnidata = data;
            state.show_completeprofile = (data.full_profile == 0) ? true : false;
        },
        set_hide_in_session_completeprofile(state, status){
            state.hide_in_session_completeprofile = status;
            window.sessionStorage.setItem('set_hide_in_session_completeprofile', true);
        }
    }
}

export default alumni;
