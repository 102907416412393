<template>
    <div class="cover row-center">
        <div class="popup">
            <h2>{{$t(`confirmmessages['${how}'].titulo`)}}</h2>
            <p>{{$t(`confirmmessages['${how}'].mensaje`)}}</p>
            <div class="contenedor-botones row-center">
                <a @click="$emit('accept')" :class="'boton-azul'" :isnegative="isnegative" :loading="loading">Aceptar</a>
                <a @click="$emit('cancel')" class="boton-gris">Cancelar</a>
            </div>
        </div>
    </div>
</template>

<script>

    export default{
        name : 'popup-confirmacion',
        props : ['how','loading','isnegative']
    }

</script>

<style lang="scss" scoped>

.cover {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.popup {
  background: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  min-height: 200px;
}

.popup h2 {
  margin-bottom: 20px;
  font-size: 26px;
  text-align: center;
}

.popup p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  display: inline-block;
}


.boton-azul[isnegative=true]{
    background:#d42b2b;
}

</style>