<template>
    <div>
        <template v-for="(el,key) in modulos">

            <template v-if="el">
                <div :key="key" v-if="el.tipo =='texto'" class="parrafo">
                    <p v-html="el.texto"></p>
                </div>

                <div :key="key" class="imagen-container" v-if="el.tipo == 'imagen'">
                    <img :src="el.enlace" v-if="el.tipo == 'imagen'" class="img-full">
                    <span v-html="el.pie" v-if="el.tipo == 'imagen'" class="pie"></span>
                </div>

                <h4 :key="key" :nivel="el.nivel" v-if="el.tipo == 'destacado'" class="destacado" v-html="el.texto"></h4>

                <h2 :key="key" v-if="el.tipo == 'titulo'" class="title--sup -green-line -black" v-html="el.value"></h2>

                <iframe :key="key" :src="$options.filters.urlToID(el.enlace)" v-if="el.tipo == 'video'"></iframe>

                <div :key="key" class="contenedor-link row-center" v-if="el.tipo == 'link'">

                    <div class="link row-start">
                        <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                        <img v-if="!!el.meta.image" :src="el.meta.image.url" alt="">
                        <h2>{{el.meta.title}}</h2>
                        <p>{{el.meta.description}}</p>
                    </div>

                </div>

                <div :key="key" class="descargable row-center" v-if="el.tipo == 'file'">
                    <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                    <p v-if="!!el.titulo">{{el.titulo}} <a href="" class="download-link">Descargar</a>
                    </p>
                </div>

                <ul :key="key" v-if="el.tipo == 'lista' && el.estilo == 'unordered'">
                    <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                </ul>

                <ol :key="key" v-if="el.tipo == 'lista' && el.estilo == 'ordered'">
                    <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                </ol>


                <div :key="key" class="boton" v-if="el.tipo == 'boton'">
                    <a target="_blank" :href="el.enlace" class="btn -skull-blue">{{el.texto}}</a>
                </div>


                <div class="table-container" :key="key" v-if="el.tipo == 'tabla'">
                    <table>
                        <tr v-for="(td,tdkey) in el.filas" :key="tdkey">
                            <td :key="ekey" v-for="(e,ekey) in td" v-html="e">

                            </td>
                        </tr>
                    </table>
                </div>

                <div class="slider-imagenes" :key="key" v-if="el.tipo == 'gallery'">

                    <slick ref="slick" :options="slickOptions">

                        <div class="slide" v-for="(img,key) in el.imagenes" :key="key">
                            <img :src="img.enlace" :alt="img.pie">
                        </div>
                    </slick>

                </div>

                <div class="columnas row-center" v-if="el.tipo == 'columnas'" :key="key">
                    <div class="columna" v-for="(el,key) in el.columnas" :key="key">
                        <editorjs :modulos="el"></editorjs>
                    </div>
                </div>


            </template>

        </template>
    </div>
</template>


<script>

    import Slick from 'vue-slick';

    import 'slick-carousel/slick/slick.css';

    export default {
        name: 'editorjs',
        props: ['modulos'],
        data: () => ({
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                dots: true,
                arrows: false,
                adaptiveHeight: false
            },
        }),
        components: { Slick },
        filters: {
            urlToID(value) {

                let string;

                if (value.includes('youtube')) {
                    string = 'https://www.youtube.com/embed/' + value.split("?v=")[1];
                } else if (value.includes('vimeo')) {
                    string = 'https://player.vimeo.com/video/' + value.split("/").pop();
                }

                return string;
            }
        },
    }
</script>