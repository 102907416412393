<template>
    <div class="date-selector">
        <v-date-picker v-model="date" mode="date" :masks="masks" @dayclick="emit">
            <template v-slot="{ inputValue, inputEvents }">
                <label :for="name">{{label}}</label>
                <input :value="inputValue" v-on="inputEvents" type="text" class="calendar" :fill="date"
                    autocomplete="off" />
            </template>
        </v-date-picker>
    </div>
</template>


<script>

    import moment from 'moment';

    export default {
        name: 'datepicker',
        props: ['label', 'name', 'minpage', 'value'],
        mounted() {



            if (this.value) {
                this.date = new Date(this.value);
                
            }


        },
        data() {
            return {
                date: '',
                masks: {
                    input: 'DD/MM/YYYY',
                    data: 'DD/MM/YYYY'
                },
            }
        },
        methods: {
            emit() {
                let date = this.date;

                this.$emit('input', moment(date).format('YYYY/MM/DD'));
            }
        },
        watch: {
            'value'() {
                this.date = new Date(this.value);
             
            }
        }
    }
</script>


<style lang="scss" scoped>
    .date-selector,
    .year-datepicker {
        label {
            width: 100%;
            display: inline-block;
            pointer-events: none;
            text-align:left;
            color:#003466;
        }

        &.failed {
            input {
                border: 1px solid red;
                background-color: white;
            }
        }
    }
</style>