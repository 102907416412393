
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';

import moment from 'moment';

const contents = {
    state: {

    },
    getters: {
    },
    actions: {
        requestEntries({ commit }, data) {
            data = {
                "filterPack": true,
                "filters": [
                    {
                        "name": "parents",
                        "search": 1
                    }
                ],
                order: [
                    {
                        name: "created_at",
                        direction: 'desc'
                    }
                ]

            }
            return new Promise((resolve) => {
                axios.post('/api/entry', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        requestEntrie({ commit }, slug) {

            return new Promise((resolve) => {
                axios.get('/api/entry/' + slug).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        requestEntrevistas({ commit }, data) {

            return new Promise((resolve) => {
                axios.post('/api/interview', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        requestEntrevista({ commit }, slug) {
            console.log(slug);
            return new Promise((resolve) => {
                axios.get('/api/interview/' + slug).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        requestEmprendedores({ commit }) {

            let data = {
                "order": [
                    {
                        name: 'created_at',
                        direction: 'desc'
                    }
                ]
            }

            return new Promise((resolve) => {
                axios.post('/api/business_creator/', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        getAutocomplete({ commit }, data) {

            let urls = {
                'study': '/api/masterdata/study/autocomplete?term=',
                'university': '/api/masterdata/university/autocomplete?term=',
                'traicing': '/api/masterdata/tracing_type/autocomplete?term=',
                'sector': '/api/masterdata/sector/autocomplete?term=',
                'langs': '/api/masterdata/langs/autocomplete?term=',
                'personal_type': '/api/masterdata/personal_type/autocomplete?term=',
                'area': '/api/masterdata/area/autocomplete?term=',
                'alumni': '/api/alumni/autocomplete?term=',
                'user': '/api/user/autocomplete?term=',
                'ufv': '/api/masterdata/university/autocomplete/ufv?term='
            }

            return new Promise((resolve) => {
                axios.get(urls[data.type] + data.term).then(response => {
                    resolve(response.data);
                })
                    .catch(error => {
                        commit('setCustomAlert', error.response.data.messages);
                        resolve(false);
                    });
            });
        },

        requestEventos({ commit }) {
            let data = {
                filters: [
                    {
                        name: 'date',
                        search: `${moment().format('YYYY/MM/DD')}T00:00%datetime-local%2080/11/31T23:59`
                    },
                    {
                        name: 'category.parents',
                        search: 1
                    },
                    {
                        name: 'category_id',
                        search: 17,
                        not: true,
                    }
                ],
                length: 4
            }

            return new Promise((resolve) => {
                axios.post('/api/event', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        requestAllEventos({ commit }, data) {
            data = {
                filters: [
                    {
                        name: 'category.parents',
                        search: 1
                    },
                ]
            }

            return new Promise((resolve) => {
                axios.post('/api/event', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },



        requestEventosPasados({ commit }) {
            let data = {
                filters: [
                    {
                        name: 'date',
                        search: `2000/08/05T00:00%datetime-local%${moment().subtract(1, 'days').format('YYYY/MM/DD')}T00:00`
                    },
                    {
                        name: 'category.parents',
                        search: 1
                    },

                ],
                length: 4
            }

            return new Promise((resolve) => {
                axios.post('/api/event', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        requestEventoEco({ commit }) {



            let data = {
                filters: [
                    {
                        name: 'category.id',
                        search: 17
                    },
                    {
                        name: 'category.parents',
                        search: 1
                    },
                    {
                        name: 'date',
                        search: `${moment().format('YYYY/MM/DD')}T00:00%datetime-local%2080/11/31T23:59`
                    },
                ],
                length: 4
            }

            return new Promise((resolve) => {
                axios.post('/api/event', data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        requestEvento({ commit }, slug) {

            return new Promise((resolve) => {
                axios.get('/api/event/' + slug).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },


        requestInscripcion({ commit }, data) {
            return new Promise((resolve) => {
                axios.post('api/event/register/open/' + data.event_id, data).then(response => {
                    if (response.data.status) {
                        resolve(true);
                    } else {
                        resolve(false);
                        if (response.data.error == 0) {
                            commit('setAlert', 1007);
                        }
                    }
                    resolve(response.data.status);
                }).catch(error => {
                    if (error.response.status == '403') {
                        resolve(error.response.status);
                    } else {
                        commit('setCustomAlert', error.response.data.messages);

                    }
                    resolve(false);
                });
            });
        },

        requestCancelInscripcion({ commit }, id) {

            return new Promise((resolve) => {
                axios.post('/api/event/cancel/' + id).then(response => {
                    if (response.data.status) {
                        resolve(true);
                        commit('setAlert', 1006);
                    } else {
                        resolve(false);

                    }
                    resolve(response.data.status);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        },

        sendContact({ commit }, data) {
            return new Promise((resolve) => {
                axios.post('/api/contacto', data).then(response => {
                    if (response.data.status) {
                        resolve(true);
                        commit('setAlert', 1009);
                    } else {
                        resolve(false);

                    }
                    resolve(response.data.status);
                }).catch(error => {
                    commit('setCustomAlert', error.response.data.messages);
                    resolve(false);
                });
            });
        }




    },
    mutations: {

    }
}

export default contents;
