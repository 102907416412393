<template>
    <div>

        <div class="nav-top row-between">

            <a class="menu-show row-start" @click="showmenu = !showmenu"><span><i></i><i></i><i></i></span>Menú</a>

            <h1>
                <router-link to="/"><img class="logo-padres" src="/img/logo-padres-head.png" width="200px" alt="logo-padres"></router-link>
            </h1>

            <span></span>

        </div>

        <div class="menu column-between aifs" :active="showmenu">

            <a class="cerrar-menu" @click="showmenu = !showmenu">✕</a>

            <div>

                <router-link class="logo-container" to="/"><img src="/img/logo-padres.png" class="logo-padres logo-menu" alt="logo-padres"></router-link>

                <nav class="real-menu column-start">

                    <router-link class="que-somos" to="/#quienes-somos" data="equipo-alumni">Quiénes somos</router-link>
                    <router-link class="eventos" to="/#eventos" data="vida-alumni">Eventos</router-link>
                    <router-link class="noticias" to="/#noticias" data="vida-alumni">Noticias</router-link>
                    <router-link class="contacto" to="/contacto">Contacto</router-link>
                    <a class="alumni" href="https://www.alumni-ufv.es/" target="_blank">Web Alumni UFV</a>
                    <a class="webUFV" href="https://www.ufv.es/" target="_blank">Web UFV</a>


                </nav>

            </div>

            <div>


                <ul class="social row-center">
                    
                    <li><a target="_blank" href="https://www.instagram.com/ufvfamilias/"><i
                                class="fa fa-instagram"></i></a></li>
                    <li><a target="_blank" href="https://educamedia.ufv.es/category/Vida+universitaria%3EFamilias/262079442"><i class="fa fa-youtube"></i></a></li>
                    <li><a target="_blank" href="https://open.spotify.com/show/0lZY95pi1dh152jPtewiF1?si=voqRPlkpRtmb3_Vr0g5fQw"><i class="fa fa-spotify"></i></a></li>
                    
                </ul>

                <p class="menu-mail"><a href="mailto:familias@ufv.es">familias@ufv.es</a></p>

            </div>

        </div>

        <div class="cover-menu" :active="showmenu" @click="showmenu = !showmenu"></div>

    </div>

</template>


<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'header-app',
        data: () => ({
            showmenu: false,
            showvidaalumni: false,
            showequipoalumni: false
        }),
        methods: {
            ...mapActions(['toggleMenuPerfil'])
        },
        computed: {
            ...mapGetters({
                isLogged: 'getLogged'
            })
        },
        watch: {
            '$route'() {
                this.showmenu = false;
            }
        }
    }
</script>