<template>
    <div class="range-selector">
        <v-date-picker v-model="range" mode="date" :masks="masks" is-range  :columns="$screens({ default: 1, lg: 2 })" @dayclick="emit">
            <template v-slot="{ inputValue, inputEvents }">
                <div class="row-start" v-if="!single">
                    <input class="calendar" type="text" :value="inputValue.start" v-on="inputEvents.start" :fill="!!range.start" placeholder="Desde"/>
                    <svg width="20" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="#bcbcbc"
                            d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                    <input class="calendar" type="text" :value="inputValue.end" v-on="inputEvents.end" :fill="!!range.end" placeholder="Hasta"/>
                </div>

                <div class="contenedor-input" v-if="single">
                    <p class="fake-input calendar" v-on="inputEvents.start"><span v-if="!!inputValue.start">{{inputValue.start}} - {{inputValue.end}}</span></p>
                </div>

            </template>
        </v-date-picker>
    </div>
</template>


<script>
    export default {
        name: 'range-selector',
        props : ['single'],
        data() {
            return {
                range: {
                    start: '',
                    end: '',
                },
                masks: {
                    input: 'DD/MM/YYYY',
                    data : 'DD/MM/YYYY'
                },
            }
        },
        methods : {
            emit(){
                this.$emit('input', this.range);
            }
        }
    }
</script>